import React from "react";

// form: {

//   },
export const themes = {
  name: 77,
  light: {
    foreground: "#000000",
    background: "#eeeeee",
  },
  dark: {
    foreground: "#ffffff",
    background: "#222222",
  },
};

export const ThemeContext = React.createContext(
  themes // default value
);

export const FormContext = React.createContext({
  type: "1",
  data: {},
  setData: () => {},
  persist: (context) => {
    let str = JSON.stringify(context.data);
    const f = localStorage.setItem("formdata", str);
  },
  readPersistData: (context) => {
    const str = localStorage.getItem("formdata");
    return JSON.parse(str);
  },
  clear: ()=>{
    localStorage.removeItem("formdata");
  }
}); //* 创建一个初始化数据，可以为Array，Object
