import React, { Component } from "react";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FormContext, ThemeContext } from "../../context/formcontext";

export function Form2Confirm() {
  let navigate = useNavigate();
  const formContext = useContext(FormContext);
  const dataMain = formContext.readPersistData();

  function submitData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ title: "React Hooks POST Request Example" }),
    };
    fetch("https://reqres.in/api/posts", requestOptions)
      .then((response) => response.json())
      // .then((data) => setPostId(data.id));
  }

  return (
    <div>
      <h2>form2 confirmpage</h2>
      <div></div>
      <div>
        <label htmlFor="">氏名2:{dataMain.username2}</label>
      </div>
      <div>
        <label htmlFor="">phone:{dataMain.phone}</label>
      </div>
      <div>
        <label htmlFor="">email:{dataMain.email}</label>
      </div>
      <button type="button" onClick={submitData}>
        send data
      </button>
      <br />

      <button type="button" onClick={() => navigate("/")}>
        toHome
      </button>
    </div>
  );
}
