import React, { Component } from "react";
import { useState } from "react";
import { FormContext } from "../../context/formcontext";
import { useNavigate } from "react-router-dom";

export function Form2(data) {
  const [dataMain, setDataMain] = useState({
    username2: "",
    phone: "",
    email: "",
  });

  let navigate = useNavigate();

  const formContext = React.useContext(FormContext);

  function setDataMainField(evt) {
    var field = evt.target.name;
    var value = evt.target.value;
    console.log(`${field};${value}`);
    console.log(dataMain);
    var newObj = {};
    newObj[field] = value;
    const extendedJson = Object.assign({}, dataMain, newObj);
    // dataMain[field] = value;
    setDataMain(extendedJson);
  }

  function handleSubmit(e) {
    // e.preventDefault();
    console.log(dataMain);
    formContext.setData(dataMain);
    navigate("/form2confirm");
  }

  return (
    <div>
      Form2
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="">氏名2:</label>
          <input
            type="text"
            name="username2"
            value={data.username2}
            onChange={(event) => setDataMainField(event)}
          />
        </div>
        <div>
          <label htmlFor="">phone:</label>
          <input
            type="text"
            name="phone"
            value={data.phone}
            onChange={(event) => setDataMainField(event)}
          />
        </div>
        <div>
          <label htmlFor="">email:</label>
          <input
            type="text"
            name="email"
            value={data.email}
            onChange={(event) => setDataMainField(event)}
          />
        </div>
        <button type="submit">submit</button>
      </form>
    </div>
  );
}
