import React, { Component } from "react";
import { useState, useContext } from "react";
import { useNavigate,redirect } from "react-router-dom";
import { FormContext,  } from "../../context/formcontext";

export function Result1() {
  let navigate = useNavigate();
  const formContext = useContext(FormContext);

  return (
    <div>
      <h2>登録完了しました</h2>
      <button type="button" onClick={() => navigate("/",{replace:true})}>
        toHome
      </button>
    </div>
  );
}
