import React, { Component } from "react";
// import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FormContext } from "../../context/formcontext";
import { useForm, } from "react-hook-form";

export function Form1() {
  let navigate = useNavigate();
  // const { username, email } = useState();
  let formContext = React.useContext(FormContext);
  // let theme = useContext(ThemeContext);

  const onSubmit = (data) => {
    // alert(12);
    console.log(data);
    formContext.setData(data);
    navigate("/form1confirm");
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues
  } = useForm({
    defaultValues: {
      username: 'kevin',
      email: 'shikaiwenchina@gmail.com',
      email2: 'shikaiwenchina@gmail.com',
      specode:'01234567890123456789',
      phone:'08087203198',
      chkbox1:'true',
      curcompany:'1'
    }
  });

  // console.log(watch("example")); // watch input value by passing the name of it

  const validatePhone = (value)=>{
    // 固定
    let fix = /^0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4}$/;
    // 携帯 IP
    let mobile = /^(050|070|080|090)\d{4}\d{4}$/;
    return fix.test(value) || mobile.test(value);
  }


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="">名前（必須）:</label>
          <input {...register("username", { required: true })} />
          {errors.username && <span>This field is required</span>}
        </div>
        <div>
          <label htmlFor="">email:</label>
          <input
            {...register("email", {required:true,
              pattern: /^\S+@\S+\.\S+$/,
            })}
          />
          {errors.email && errors.email.type==="required" && <span>This field is required</span>}
          {errors.email && errors.email.type==="pattern" && <span>メール形式が違う</span>}
        </div>
        <div>
          <label htmlFor="">email確認用:</label>
          <input type="text" {...register("email2", {
            required:true,
            validate: (value) =>{
              return value === getValues("email") || "The number of servings must be an even number"
            }
            },
            
            )}/>
          {errors.email2 && errors.email2.type==="validate" && <span>buiyyyyd</span>}
          {errors.email2 && errors.email2.type==="required" && <span>it is required </span>}
        </div>
        <div>
          <label htmlFor="">電話番号:</label>
          <input {...register("phone", {
            required:true,
              validate: validatePhone
            },
            )}/>
          {errors.phone && errors.phone.type==="required" && <span>phone is required</span>}
          {errors.phone && errors.phone.type==="validate" && <span>give me a real one </span>}

        </div>
        <div>
          <label htmlFor="">生年月日:</label>
          <input />年
          <input />日
        </div>
        <div>
          <label htmlFor="">郵便番号:</label>
          <input />
        </div>
        <div>
          <label htmlFor="">都道府県:</label>
          <input />
        </div>
        <div>
          <label htmlFor="">市区町村:</label>
          <input />
        </div>
        <div>
          <label htmlFor="">住所:</label>
          <input />
        </div>
        <div>
          <label htmlFor="">番地:</label>
          <input />
        </div>
        <div>
          <label>建物名:</label>
          <input />
        </div>
        <div>
          <label>供給地点特定番号２０桁:</label>
          <input {...register('specode',{
            required:true,
            validate:(value)=>/^\d{20}$/.test(value)
            })}/>
          {errors.specode && errors.specode.type==="required" && <span>it is required</span>}
          {errors.specode && errors.specode.type==="validate" && <span>20桁の数字</span>}
        </div>
        <div>
          <label>利用中の電力会社:</label>
          <select {...register('curcompany',{required:true})}>
            <option value=''>--選択--</option>
            <option value='1'>東京電力</option>
            <option value='2'>関西電力</option>
          </select>
          {errors.curcompany && errors.curcompany.type==="required" && <span>it is required</span>}
        </div>
        <div>
          <label>契約締結前交付書面:</label>
          <input type="checkbox" {...register('chkbox1',{required:true})}/>
          {errors.chkbox1 && errors.chkbox1.type==="required" && <span>it is required</span>}
        </div>
        <button type="button" onClick={() => navigate(-1)}>
          Back
        </button>
        <button type="submit">送信</button>
      </form>
    </div>
  );
}


