import React, { Component } from "react";
import { useState, useContext } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { FormContext, ThemeContext } from "../../context/formcontext";

export function Form1Confirm() {
  let navigate = useNavigate();
  const formContext = useContext(FormContext);
  const dataMain = formContext.readPersistData();

  function submitData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataMain),
    };
    //http://127.0.0.1:8000/create
    fetch("http://13.230.56.237:8000/create", requestOptions)
      .then((response) => {
        console.log(response)
        if(response && response.ok === true){
          formContext.clear();
          navigate("/result1",{replace:true});
          // navigate("/",{replace:true})
          // return <Navigate to="/new-location" replace />;
        }
      }
        )
      // .then((data) => setPostId(data.id));
  }

  return (
    <div>
      <h2>form1 confirmpage</h2>
      <div></div>
      <div>
        <label htmlFor="">氏名2:{dataMain.username2}</label>
      </div>
      <div>
        <label htmlFor="">phone:{dataMain.phone}</label>
      </div>
      <div>
        <label htmlFor="">email:{dataMain.email}</label>
      </div>
      <button type="button" onClick={submitData}>
        send data
      </button>
      <br />

      <button type="button" onClick={() => navigate("/")}>
        toHome
      </button>
    </div>
  );
}
