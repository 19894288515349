import { StrictMode } from "react";
import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
  Link,
} from "react-router-dom";
import ReactDOM from "react-dom";

import { FormContext, ThemeContext } from "./context/formcontext";

import { mainRoute } from "./routes";
import App from "./App";
import { Form1 } from "./views/form1";
import { Form1Confirm } from "./views/form1/confirm";
import { Result1 } from "./views/form1/result";

import { Form2 } from "./views/form2";
import { Form2Confirm } from "./views/form2/confirm";


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const ReactContext = React.createContext({ name: "kevin" });

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <h1>Form List</h1>
        <Link to="form1">Form1</Link>
        <br />
        <Link to="form2">Form2</Link>
        <br />
        <Link to="testContext">testContext</Link>
      </div>
    ),
  },
  {
    path: "form1",
    element: <Form1 />,
  },
  {
    path: "form1confirm",
    element: <Form1Confirm />,
  },
  {
    path: "result1",
    element: <Result1 />,
  },
  
  {
    path: "form2",
    element: <Form2 />,
  },
  {
    path: "form2confirm",
    element: <Form2Confirm/>,
  },
  {
    path: "testcontext",
    element: <Form2 />,
  },
]);

function AppAll() {
  const formContext = React.useContext(FormContext);
  formContext.setData = setFormData;

  function setFormData(fdata) {
    formContext.data = fdata;
    formContext.persist(formContext);
    // alert(JSON.stringify(fdata));
  }

  return (
    <div>
      <FormContext.Provider value={formContext}>
        <RouterProvider router={router} />
      </FormContext.Provider>
    </div>
  );
}
root.render(<AppAll />);
